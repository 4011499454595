import hehe from './images/hehe.jpg'
import reactTodo from './images/react-todo.jpg'
const slides = [
  {
    imgSrc: reactTodo,
    name: "To Do",
    description: "A task organization project developed during my university days. Built with React and Firebase. For more details, refer to the GitHub repository.",
    code: "https://github.com/Elaminebendaas/todo-react",
    demo: "https://todo.elaminebendaas.vercel.app/"
  },
  {
    imgSrc: hehe,
    name: "A project",
    description:
      "a project will be added here",
      code: "https://github.com/Elaminebendaas",
      demo: "https://google.com"
  },
  {
    imgSrc: hehe,
    name: "To Do",
    description:
      'A project will be added here.',
      code: "https://github.com/Elaminebendaas/todo-react",
    demo: "https://todo.elaminebendaas.vercel.app/"
  },
  {
    imgSrc: hehe,
    name: "A project",
    description:
      "a project will be added here",
      code: "https://github.com/Elaminebendaas",
      demo: "https://google.com"
  },
  {
    imgSrc: hehe,
    name: "A Project",
    description:
      "a project will be added here",
    code: "https://github.com/Elaminebendaas",
    demo: "https://google.com"
  }
];
export default slides;
